<template>
  <nav class="navbar">
    <div class="logo">
      <!-- <img
        :src="require('@/page/namvaytien/components/img/logo.png')"
        :alt="logo"
        class="logoImg"
      /> -->
      <span class="title">TAN DAT PHAT STEEL COMPANY LIMITED</span>
    </div>
    <ul class="nav-links">
      <li
        :class="{ 'active-link': isActive('/') }"
    
      >
        <router-link to="/" class="nav-link1">
          <span class="routeText1">Giới thiệu công ty</span>
        </router-link>
      </li>
      <li :class="{ 'active-link': $route.path === '/about' }">
        <router-link to="/about" class="nav-link2">
          <span class="routeText2">Vay ngay bây giờ</span>
        </router-link>
      </li>
      <li
        :class="{ 'active-link': $route.path === '/question' }"
      >
        <router-link to="/question" class="nav-link3">
          <span class="routeText3">Các câu hỏi thường gặp</span>
        </router-link>
      </li>
      <li :class="{ 'active-link': $route.path === '/protocol' }">
        <router-link to="/protocol" class="nav-link4">
          <span class="routeText4">Thỏa thuận quyền riêng tư</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      logo: "NAM A ASSET MANAGEMENT COMPANY LIMITED",
      backgroundImage: "../../src/page/namvaytien/components/img/Rectangle.png",
      link1Color: "white", // 初始颜色，可以是你希望的颜色
    };
  },
  methods: {
   
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  height: 120px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  background-color: #005A3A;
  min-width: 1400px;
  max-width: 1920px;
}

.logo {
  font-weight: bold;
  color: black;
  display: flex; /* 让 Logo 内容成为一个 flex 容器，使图片和文字水平排列 */
  align-items: center; /* 垂直居中对齐 */
  padding-left: 10px;
}

.logoImg {
  width: 50px;
  height: 50px;
}
.title {
  width: 520px;
  height: 24px;
  font-size: 18px;
  font-family: SF Pro Display, SF Pro Display;
  font-weight: 500;
  color: #000000;
  line-height: 23px;
  padding-left: 16px;
}
.routeText1,
.routeText2,
.routeText3,
.routeText4 {
  font-size: 16px;
  font-family: SF Pro Display, SF Pro Display;
  display: flex; /* 设置为弹性盒子 */
  align-items: center; /* 垂直居中 */
  text-align: center;
}
.routeText1 {
  width: 160px;
  padding-left: 8px;
  padding-right: 8px;
}
.routeText2 {
  width: 160px;
  padding-left: 8px;
  padding-right: 8px;
}
.routeText3 {
  width: 210px;
  padding-left: 8px;
  padding-right: 8px;
}
.routeText4 {
  width: 220px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}
.nav-links {
  list-style: none;
  display: flex;
}

.nav-links li {
  height: 49px;
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4 {
  text-decoration: none;
 /* 将链接文字转为大写 */
  /* text-transform: uppercase; */
  font-size: 16px;
  font-family: SF Pro-Semibold;
}

.nav-link1 {
  height: 49px;

  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #170101;
  line-height: 39px;
}

.nav-link2 {
  height: 49px;

  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #000000;
  line-height: 39px;
}
.nav-link3 {
  height: 49px;

  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #000000;
  line-height: 39px;
}
.nav-link4 {
  height: 49px;

  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #000000;
  line-height: 39px;
}
.nav-links li {
  padding: 5px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 49px;
}
.active-link {
  background: url("@/page/donghaisoftware/components/img/Rectangle2.png");
  background-size: 100% 100%; /* 背景图片充满整个容器 */
  background-repeat: no-repeat;
}
.active-link .routeText {
  color: #005A3A;
}
.active-link span {
  color: #005A3A;
}
.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.title{
  color: #FFFFFF;
}

.navbar{
  max-width: 1620px;
}
</style>